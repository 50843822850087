import React from "react";

import title_decoration from "../assets/images/title-decoration.png";
import Certificate from "../assets/images/Certificate.png";

{/*import '../assets/css/nivo-slider.css';
import '../assets/css/owl.carousel.min.css';
import '../assets/css/owl.theme.default.min.css';*/}

function Home() {
  return (
    <body>
      <main className="main-content contact_us-wrapper">
        <div className="page__banner">
            <div className="container">
                <div className="banner-content">
                    <h2 className="title">Contact Us</h2>
                    <p className="description">
                        We Welcome Your Enquiries, Comments And Suggestions
                    </p>
                </div>
            </div>
        </div>

        <div className="content-wrapper">
            <div className="container">


                <div className="two-col-grid">
                    <div className="grid-child get-in-touch">
                        <div className="content__wrapper">
                            <div className="slideDown-animate-wrapper title-font">
                                <h2 className="row-title">Get In Touch With Us</h2>
                                <img decoding="async" width="513" height="50"
                                    src={title_decoration} className="" alt="Image"/>
                            </div>

                            
                            <div className="row-description-wrapper">
                                <div>
                                    <h2 className="title">
                                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        Registered Office
                                    </h2>

                                    <p className="sub-title">
                                        KR Placement Services Pvt Ltd
                                    </p>
                                    <address className="address">
                                        N0. 169-2B<br />
                                        West Street, neduvakottai<br />
                                        Mannargudi. PIN-614014<br />
                                        Tamil Nadu, India.
                                    </address>
                                </div>
                            </div>

                            <div className="row-description-wrapper ">
                                <div>
                                    <h2 className="title">
                                        <i className="fa fa-address-book" aria-hidden="true"></i>
                                        Contact Us
                                    </h2>

                                    {/* <p className="sub-title contact_us-wrapper">
                                        <a href="tel:044 3537 8488" className="contact">
                                            <i className="fa fa-phone" aria-hidden="true"></i>
                                            044 3537 8488
                                        </a>
                                    </p> */}
                                    <p className="sub-title contact_us-wrapper">
                                        <a href="tel:+916379797483" className="contact">
                                            <i className="fa fa-mobile" aria-hidden="true"></i>
                                            +91 63797 97483
                                        </a>
                                    </p>
                                    <p className="sub-title contact_us-wrapper">
                                        <a href="tel:+919003883151" className="contact">
                                            <i className="fa fa-mobile" aria-hidden="true"></i>
                                            +91 90038 83151
                                        </a>
                                    </p>
                                    <p className="sub-title contact_us-wrapper">
                                        <a href="tel:+919363903022" className="contact">
                                            <i className="fa fa-mobile" aria-hidden="true"></i>
                                            +91 93639 03022
                                        </a>
                                    </p>
                                </div>
                            </div>

                            <div className="row-description-wrapper">
                                <div>
                                    <h2 className="title">
                                        <i className="fa fa-envelope" aria-hidden="true"></i>
                                        E-Mail Us
                                    </h2>

                                    <div className="address">
                                        For Job-seekers, please e-mail your CV to<br />
                                        <a className="mail"
                                            href="mailto:admin@krplacementservices.in">admin@krplacementservices.in</a>
                                    </div>
                                    <div className="address">
                                        For Corporate clients to hire, please e-mail us<br />
                                        <a className="mail" href="mailto:hr@krplacementservices.in">hr@krplacementservices.in</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid-child">
                        <div className="content__wrapper">
                            <div className="slideDown-animate-wrapper title-font">
                                <h2 className="row-title">How Can We Help?</h2>
                                <img decoding="async" width="513" height="50"
                                    src={title_decoration} className="" alt="Image"/>
                            </div>

                            <div className="row-description-wrapper">
                                <form className="get_in_touch-form">
                                    <fieldset>
                                        <label>Mobile <span className="required-star">*</span></label>
                                        <input type="tel" placeholder="" name="" />
                                    </fieldset>
                                    <fieldset>
                                        <label>E-mail <span className="required-star">*</span></label>
                                        <input type="email" placeholder="" name="" />
                                    </fieldset>
                                    <fieldset>
                                        <label>Enquiry</label>
                                        <select>
                                            <option value="Sales">Sales</option>
                                            <option value="Career">Career</option>
                                            <option value="Recruitment">Recruitment</option>
                                            <option value="Request for Information/Proposal">Request for Information/Proposal</option>
                                            <option value="General">General</option>
                                            <option value="Education">Education</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </fieldset>
                                    <fieldset>
                                        <label>How can I help you?</label>
                                        <textarea name="" id=""></textarea>
                                    </fieldset>

                                    <div className="action_wrapper">
                                        <button className="primary-btn">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                

            </div>
            <div className="map-pad">
            <iframe src="https://maps.google.com/maps?width=1024&amp;height=350&amp;hl=en&amp;q=KR INTERNATIONAL SERVICE&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="100%" height="350" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
        </div>
    </main>
      
    </body>
  );
}

export default Home;
